import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { isProductionEnv } from '../utils/helper';
import DevTools from '../containers/DevTools';

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middlewares = [thunk, routerMiddleware(history)];

if (!isProductionEnv()) middlewares.push(createLogger());

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  DevTools.instrument(),
  ...enhancers,
);

const configureStore = () => {
  const store = createStore(rootReducer, initialState, composedEnhancers);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
